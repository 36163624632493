.AppDatePicker {
    margin-top: 0px !important;
    .MuiFormControl-root {
        width: 200px;
        @media screen and (max-width: 720px) {
            width: 100%;
        }
    }
    .MuiInputBase-root {
        border-radius: 4px !important;
        background-color: var(--input-bg);
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiOutlinedInput-input {
        border-radius: 14px;
        background-color: var(--input-bg);
        color: var(--main-text-color);
        font-size: 14px;
        padding: 14px 15px;
    }
    .MuiOutlinedInput-root {
        height: 51px;
    }
    .MuiIconButton-root {
        position: absolute;
        right: 20px;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover {
    .MuiOutlinedInput-input {
        background-color: var(--input-bg);
        }
    }
}
