@mixin md {
  @media screen and (max-width: 992px) {
    @content
  }
}

.landing {
  --accent-color: #1023A2;
  --light-accent: #2D45EA;
  --paper-bg: #fff;
  --title-color:  #030D03;
  --text_color: #000000;
  --light-text-color: #fff;

  margin-top: 90px;
  background: #fff;

  .hide_on_mobile {
    @include md {
      display: none;
    }
  }

  .container {
    max-width: 1232px;
    margin: 0 auto;
    padding: 0 20px;
    @include md {
      padding: 0 24px;
    }
  }

  .button {
    display: inline-block;
    padding: 16px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    font-weight: 600;
    background: var(--accent-color);
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s;
    &[disabled] {
      pointer-events: none;
      opacity: 0.6;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  a {
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  .title {
    font-size: 50px;
    line-height: 56px;
    font-weight: 500;
    text-align: center;
    color: #000;
    @include md {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    max-height: 112px;
    padding: 20px 0;
    background: #0033A1;
    z-index: 100;

    &__burger {
      display: none;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      border: none;
      background: none;
      span {
        display: block;
        width: 31px;
        height: 3px;
        margin-bottom: 5px;
        background: #fff;
        border-radius: 20px;
      }
      @include md {
        display: block;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      @include md {
        flex-direction: column;
      }
    }

    .logo {
      &__image {
        max-height: 50px;
      }
      margin-right: 160px;
      @include md {
        align-self: flex-start;
        margin-right: 0;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      @include md {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        flex-direction: column;
        width: 100%;
        padding: 20px 0;
        background: rgba(16, 35, 162, 0.9);
        transition: 0.2s;
        &.opened {
          opacity: 1;
          pointer-events: auto;
        }
      }
      &__list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        @include md {
          width: 100%;
          margin-bottom: 15px;
          flex-direction: column;
          text-align: center;
        }
      }
      &__item {
        margin-right: 32px;
        @include md {
          margin-right: 0;
          width: 100%;
        }
      }

      &__link {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        white-space: nowrap;
        @include md {
          display: inline-block;
          padding: 10px;
          margin-right: 0;
          width: 100%;
        }
      }

      &__button {
        padding: 9px 41px;
        //margin-left: 160px;
        background: #fff;
        font-size: 16px;
        line-height: 20px;
        color: var(--accent-color);
        text-decoration: none;
        border-radius: 12px;
      }
    }
  }
  .promo {
    min-height: calc(100vh - 90px);
    padding: 120px 0;
    margin-bottom: 12px;
    //background: linear-gradient(90.96deg, rgba(6, 54, 112, 0.99) 18.13%, rgba(9, 18, 66, 0.3) 43.08%),;
    background-size: cover;
    color: #fff;
    @include md {
      min-height: auto;
      padding: 34px 0;
    }
    &__info {
      max-width: 570px;
    }
    &__title {
      margin-bottom: 8px;
      font-weight: 600;
      text-align: left;
      color: inherit;
      @include md {
        font-size: 36px;
        line-height: 40px;
      }
    }
    &__subtitle {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 20px;
    }
    &__button {
      background: var(--light-accent);
    }

  }
  .about {
    min-height: 832px;
    margin-bottom: 140px;
    background-color: var(--accent-color);
    background-repeat: no-repeat;
    background-position: 0 50%;
    @include md {
      min-height: auto;
      margin-bottom: 12px;
      background: none !important;
    }
    &__info {
      max-width: 660px;
      margin-left: auto;
      padding-top: 130px;
      font-size: 24px;
      line-height: 30px;
      @include md {
        max-width: 100%;
        padding-top: 12px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__title {
      margin-bottom: 54px;
      color: #fff;
      text-align: center;
      @include md {
        margin-bottom: 24px;
        color: #000;
      }
    }
    &__description {
      padding: 24px 22px;
      background: #fff;
      border-radius: 20px;
      @include md {
        box-shadow: 0px 4px 12px 0px #00000040;
      }
      p {
        margin-bottom: 12px;
      }
      img {
        margin: 0 8px;
      }
    }
    &__images {
      text-align: center;
    }
  }
  .advantages {
    margin-bottom: 160px;
    @include md {
      margin-bottom: 12px;
    }
    &__title {
      margin-bottom: 66px;
      @include md {
        margin-bottom: 24px;
      }
    }
    &__list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      @include md {
        flex-direction: column;
        max-width: 430px;
        margin: 0 auto;
      }
    }
    &__item {
      max-width: 330px;
      margin-right: 80px;
      @include md {
        max-width: 100%;
        margin: 0 0 20px;
      }
      &-title {
        margin-bottom: 8px;
        color: #000;
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
      }
      &-text {
        font-size: 16px;
        line-height: 20px;
      }
      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: 48px;
        @include md {
          margin-bottom: 20px;
        }
      }
    }
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      margin-bottom: 12px;
      background: var(--accent-color);
      border-radius: 20px;
    }
  }
  .pricing {
    margin-bottom: 145px;
    @include md {
      margin-bottom: 12px;
    }
    &__title {
      margin-bottom: 60px;
      @include md {
        margin-bottom: 24px;
      }
    }

    &__plans {
      display: flex;
      justify-content: space-between;
      width: min-content;
      &-wrapper {
        margin: 0 -24px 0 0;
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &__plan {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 380px;
      padding: 70px 50px;
      margin-right: 12px;
      text-align: center;
      color: #000;
      background: #f5f5f5;
      border-radius: 20px;
      &:last-child {
        margin-right: 0;
      }
      @include md {
        width: 340px;
        padding: 40px 20px;
      }
      &-title {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
      }
      &-price {
        margin-bottom: 24px;
        padding-bottom: 24px;
        font-size: 24px;
        line-height: 30px;
        border-bottom: 1px solid var(--accent-color);
        strong {
          font-weight: 500;
        }
      }
      &-description {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
      }
      &--featured {
        color: #fff;
        background: var(--accent-color);
        .pricing__plan-price {
          border-color: #fff;
        }
        .button {
          color: var(--accent-color);
          background: #fff;
        }
      }
    }
  }
  .statistics {
    margin-bottom: 34px;
    @include md {
      margin-bottom: 0;
    }
    &__title {
      padding: 30px;
      background: rgba(255, 255, 255, 0.9);
    }
    &__inner {
      padding: 0 0 30px;
      background-size: cover;
      background-position: 50% 50%;
      @include md {
        margin-bottom: 12px;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 832px;
      @include md {
        min-height: 580px;
      }
    }
    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.mobile {
        display: none;
      }
      @include md {
        flex-wrap: wrap;
        &.desktop {
          display: none;
        }
        &.mobile {
          display: flex;
          padding: 0 24px;
          align-items: flex-start;
        }
      }
    }
    &__item {
      color: #000;
      max-width: 295px;
      margin-right: 56px;
      @include md {
        max-width: 50%;
        width: 180px;
        margin-right: 0;
        margin-bottom: 24px;
      }
      &:last-child {
        margin-right: 0;
      }
      &-value {
        margin-bottom: 8px;
        font-size: 36px;
        line-height: 40px;
        font-weight: 600;
        white-space: nowrap;
        @include md {
          font-size: 24px;
          line-height: 30px;
        }
      }
      &-description {
        font-size: 24px;
        line-height: 30px;
        @include md {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .footer {
    padding: 24px 0;
    background: var(--accent-color);
    color: #fff;
    // @include md {
    //   display: none;
    // }
    &__logo {
      margin-right: 162px;
      @include md {
        margin-right: 0;
      }
    }
    &__top {
      display: flex;
      justify-content: space-between;
      @include md {
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }
    }
    &__nav {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      @include md {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
      }
    }
    &__menu {
      margin-right: 200px;
      list-style: none;
    }
    &__column-title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
    &__contact, &__item {
      display: block;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }
    &__link, &__contact {
      color: inherit;
      text-decoration: none;
    }
    &__contact.tel {
      @include md {
        display: none;
      }
    }
    &__contact.tel-link {
      display: none;
      @include md {
        display: block;
      }
    }
  }

  .modal, .backdrop {
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    &.opened {
      pointer-events: auto;
      opacity: 1;
    }
  }
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    z-index: 200;
  }
  .modal {
    padding: 82px 24px 24px 24px;
    position: fixed;
    top: 100px;
    left: 50%;
    width: 607px;
    transform: translateX(-50%);
    text-align: center;
    color: #000;
    background: #fff;
    border-radius: 12px;
    z-index: 201;
    @include md {
      padding: 55px 12px 12px;
      top: 50px;
      width: calc(100% - 48px);
    }
    &__close {
      width: 34px;
      height: 34px;
      position: absolute;
      right: 24px;
      top: 24px;
      transition: 0.2s;
      cursor: pointer;
      @include md {
        top: 12px;
        right: 12px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    &__title {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 500;
    }
    &__info {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
      @include md {
        margin-bottom: 12px;
      }
    }
    .form {
      width: 400px;
      margin: 0 auto;
      text-align: left;
      @include md {
        width: 100%;
      }
      &__field {
        margin-bottom: 12px;
        @include md {
          margin-bottom: 8px;
        }
        &-phone {
          display: flex;
        }
      }
      &__input-phone-code {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 84px;
        padding: 14px;
        border-radius: 12px;
        margin-right: 8px;
        background-color: #F9F9F9;
        background-position: 10px 50%;
        background-repeat: no-repeat;
      }
      &__label {
        display: inline-block;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
        color: #636366;
      }
      &__input {
        width: 100%;
        padding: 16px;
        font-size: 14px;
        line-height: 20px;
        background: #F9F9F9;
        border: none;
        border-radius: 12px;
      }
      .button {
        width: 100%;
        // @include md {
        //   position: absolute;
        //   left: 50%;
        //   bottom: 0;
        //   width: 93%;
        //   transform: translateX(-50%) translateY(50%);
        // }
      }
      &__switch {
        &-checkbox {
          display: none;
          &:checked + label span {
            background: var(--light-accent);
            &::after {
              left: auto;
              right: 2px;
            }
          }
        }
        &-label {
          display: flex;
          margin-bottom: 24px;
          cursor: pointer;
          a {
            color: var(--accent-color);
            text-decoration: none;
          }
        }
        &-element {
          position: relative;
          display: inline-block;
          width: 36px;
          height: 20px;
          margin-right: 20px;
          background: #c6c6c6;
          border-radius: 11px;
          vertical-align: middle;
          transition: 0.2s;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 2px;
            width: 15px;
            height: 15px;
            background: #fff;
            transform: translateY(-50%);
            border-radius: 50%;
            transition: 0.2s;
          }
        }
      }
    }
  }
}