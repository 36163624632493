:root {
    --accent-color-rgb: 73, 193, 114;
    //--accent-color-rgb: 2, 175, 218;
    --accent-color: rgb(var(--accent-color-rgb));

    --main-text-color: #344563;
    --title-color: #344563;
    --medium-text-color: #6B778C;

    --page-bg-color: #fff;
}
[data-theme="light"] {
    --main-text-color: #344563;
    --title-color: #344563;
    --medium-text-color: #6B778C;

    --page-bg-color: #fff;
    --paper-bg: #FAFBFC;
    --border-color: #CED1D5;

    --input-bg: #F0F4F9;
    --input-bg-focused: #f7f9fc;
    --select-focused-option: #eee;

    --order-table-hover-icon: #f7f8fb;
    --order-table-icon-incorrect-details: #FFEFEF;

    --pagination-page-bg: #344563;
    --pagination-page-color: #fff;

    --theme-switcher-bg: #3445630D;
    --theme-switcher-icon-light-color: #fff;
    --theme-switcher-icon-dark-color: #6B778C;

    --sticky-header-bg: #ffffffcc;
    --sticky-header-shadow: inset 0 -1px 0 0 var(--border-color);

    --piechart-tooltip-color: #000000;

    --chip-bg: #f0f4f9;
}

[data-theme="dark"] {
    --main-text-color: #C9DCE9E5;
    --title-color: #fff;
    --medium-text-color: #C9DCE9E5;

    --page-bg-color: #272B2F;
    --paper-bg: #1E2225;
    --border-color: #42474D;

    --input-bg: #20252B;
    --input-bg-focused: #1d2227;
    --select-focused-option: rgba(33, 150, 243, 0.08);

    --order-table-hover-icon: #4A4E55;
    --order-table-icon-incorrect-details: #3D3134;

    --pagination-page-bg: #C9DCE9E5;
    --pagination-page-color: #272B2F;

    --theme-switcher-bg: #31373D;
    --theme-switcher-icon-light-color: #C9DCE9;
    --theme-switcher-icon-dark-color: #fff;

    --sticky-header-bg: #272b2f90;
    --sticky-header-shadow: inset 0 -1px 0 0 var(--border-color);

    --piechart-tooltip-color: #ffffff;

    --chip-bg: #31373D;
}

*,
*::before,
*::after {
    box-shadow: none;
    outline: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    min-height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Inter', 'Roboto', arial, sans-serif;
    background: var(--page-bg-color) !important;
    overflow: auto;
    color: var(--main-text-color);
}

.MuiPaper-root {
    background-color: var(--paper-bg);

    & .MuiPickersDay-root {
        background: var(--input-bg);
        color: var(--main-text-color);

        &.Mui-selected {
            background: var(--accent-color);
            color: #fff;
        }

        &.Mui-disabled {
            opacity: 0.4;
        }

        &:hover {
            background: rgba(var(--accent-color-rgb), 0.7);
        }
    }
}

.MuiModal-root,
[data-popper-placement] {
    z-index: 100000;
}

//scrollbar
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #E6EDF3;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 9px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

//leaflet
.leaflet-routing-container,
.leaflet-control-zoom {
    display: none;
}

.leaflet-grab {
    cursor: pointer;
}

.delivery_zones_marker:hover {
    transform: scale(1.1);
}

.leaflet-popup-content {
    margin: 0;
}

.leaflet-container a.leaflet-popup-close-button {
    display: none;
}

.leaflet-editing-icon {
    width: 10px !important;
    height: 10px !important;
    margin-top: -5px !important;
    margin-left: -5px !important;
    border-radius: 50%;
}

.leaflet-control-attribution {
    display: none;
}

//backdrop
.MuiBackdrop-root {
    backdrop-filter: blur(10px);
}

.MuiBackdrop-root.MuiBackdrop-invisible {
    backdrop-filter: none;
}