.faq {
  max-width: 1000px;
  margin: 50px auto 0;
  &_title {
    margin-bottom: 3rem;
    text-align: center;
  }
}
.accordion {
  margin-bottom: 30px;
  &_item {
    position: relative;
    overflow: hidden;
    &_title {
      padding: 15px 50px 15px 0;
      font-size: 12px;
      font-weight: 500;
      transition: 0.2s;
    }
    &_text {
      transition: 0.5s;
    }
    &.opened &_text {
      max-height: 500px;
    }
    &.opened::after {
      content: "-";
    }
  }
}
.keep_questions {
  text-align: center;
  a {
    color: var(--accent-color);
    &:hover {
      opacity: 0.7;
    }
  }
}
