.AppDatePicker {
    margin-top: 4px !important;
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiInputBase-root {
        border-radius: 14px !important;
        background-color: var(--input-bg);
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiOutlinedInput-input {
        border-radius: 14px;
        background-color: var(--input-bg);
        padding-left: 45px;
    }
    .MuiIconButton-root {
        position: absolute;
        left: 10px;
        top: 4px;
    }
}
.AppDatePickerCancelModal {
    // margin-top: 4px !important;
    .MuiFormControl-root {
        width: 200px;
        @media screen and (max-width: 720px) {
            width: 100%;
        }
    }

    .MuiInputBase-root {
        border-radius: 4px !important;
        background-color: var(--input-bg);
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiOutlinedInput-input {
        border-radius: 14px;
        background-color: var(--input-bg);
        color: var(--main-text-color);
        font-size: 14px;
        padding-left: 15px;
    }
    .MuiIconButton-root {
        position: absolute;
        left: auto;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover {
        .MuiOutlinedInput-input {
            background-color: var(--input-bg);
        }
    }
}
