.textInput {
  &_wrapper {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    &_number {
      width: auto;
    }
  }

  &_buttons {
    height: 100%;
    width: 20px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    top:50%;
    transform:translate(0, -50%);
    right: 15px;
    &_item{
      height: 50%;
      display: inline-flex;
      &:first-child{
        align-items: flex-end;
      }
      &:last-child{
        align-items: flex-start;
      }
    }
  }
}
