nav.react-contextmenu {
    position: fixed;
    width: 200px;
    left: 0;
    top: calc(100% + 10px);
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 0;
    margin: 15px 5px;
    z-index: 99;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.react-contextmenu .react-contextmenu-item:hover{
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item{
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 5px 20px;
  cursor: pointer;
  user-select: none;
  transition: .2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  outline: none;
}
/* .react-contextmenu-details span{
  margin-left: 10px;
}
.react-contextmenu-details svg{
  font-size: 14px;
}
.react-contextmenu-details .copy{
  color:skyblue
}
.react-contextmenu-details .delete{
  color:red
}
.react-contextmenu-details .send{
  color: blue
}
.react-contextmenu-details .share{
  color: green
}
.react-contextmenu-details .watchlist{
  color: rebeccapurple
} */